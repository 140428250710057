import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import { Suspense, lazy } from "react";
import { motion, useInView } from "framer-motion";
import React, { useRef } from "react";
import { Flex, Button, background, border } from "@chakra-ui/react";
import { FaArrowDown } from "react-icons/fa";

const OnboardingStep = lazy(() => import("../components/OnboardingStep"));

export default function Home() {
  const handleNavigate = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const onboardingRef = useRef(null);

  const scrollToOnboarding = () => {
    onboardingRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  return (
    <div className="App">
      <header className="App-header">
        <div className="Top-ledger">
          <Link to="/">
            <img
              src={"meshr_full_logo.png"}
              className="Top-ledger-logo"
              alt="Top left logo"
            />
          </Link>
          <div className="Right-ledger">
            <a href="/about" className="Top-ledger-link">
              About
            </a>
          </div>
        </div>
      </header>
      <img src={"meshr_logo.png"} className="App-logo" alt="Meshr logo" />
      <img src={"purple_blob.png"} className="Purple-blob" alt="Purple blob" />
      <div className="Content-wrapper-left">
        <br />
        <Flex
          style={{
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "80px",
            width: "100%",
          }}
        >
          <h1>Welcome to MESHR!</h1>
          <p style={{ textAlign: "center" }}>
            Do human rights related research <br />
            more efficiently
          </p>
          <Flex style={{ flexDirection: "column"}}>
            <Button
              className="App-button App-button-purple"
              onClick={() => handleNavigate("https://staging.meshr.org")}
            >
              Log in
            </Button>
            <Button
              className="App-button App-button-white"
              onClick={() =>
                handleNavigate(
                  "mailto:support@meshr.org?subject=MESHR sign-up&body=I'm interested in MESHR! Please sign me up!%0D%0A%0D%0A(Username and password will be sent to you soon)%0D%0A"
                )
              }
            >
              Sign up
            </Button>
          </Flex>
          <Flex
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            marginTop="40px"
          >
            <p style={{ fontSize: "22px" }}><em>In collaboration with and funded by</em></p>
            <h2 style={{ fontWeight: "bold", fontSize: "30px", marginTop: "10px" }}>
              CELL: Center for Experiential Legal Learning
            </h2>
          </Flex>
          
        </Flex>
        <Flex
          width={"100%"}
          justifyContent={"center"}
          alignItems={"center"}
          flexDirection={"column"}
        >
          <Flex
            width={"100%"}
            marginTop={"40px"}
            justifyContent={"center"}
            className="Scroll-indicator"
            alignItems="center"
            flexDirection={"column"}
            marginBottom={"200px"}
          >
            <h2>Scroll to see how it works</h2>
            <Button
              style={{
                backgroundColor: "transparent",
                border: "none",
              }}
              onClick={scrollToOnboarding}
              className="Scroll-button"
            >
              <FaArrowDown className="Scroll-arrow" />
            </Button>
          </Flex>
          <Flex
            className="onboarding-steps-container"
            ref={onboardingRef}
            style={{
              scrollSnapType: "y mandatory",
              paddingTop: "50px",
              justifyContent: "center",
              overflowY: "scroll",
              height: "100%",
              scrollbarWidth: "none",
            }}
          >
            <div
              className="Description-box"
              style={{ scrollSnapAlign: "start" }}
            >
              <Suspense fallback={<p>Loading...</p>}>
                <AnimatedOnboardingStep
                  description={
                    <>
                      <strong>1.</strong> Use our starting page with recommended
                      cases and begin your research by opening a case in a{" "}
                      <strong>graph</strong>
                    </>
                  }
                  videoUrl="1.open_case_in_graph.mkv"
                />
              </Suspense>
              <Suspense fallback={<p>Loading...</p>}>
                <AnimatedOnboardingStep
                  description={
                    <>
                      <strong>2.</strong> ...or <strong>search</strong> for a
                      case name or case contents
                      <br />
                      and get similar cases suggested to you
                    </>
                  }
                  videoUrl="2.search.mkv"
                />
              </Suspense>
              <Suspense fallback={<p>Loading...</p>}>
                <AnimatedOnboardingStep
                  description={
                    <>
                      <strong>3.</strong> See how the selected case{" "}
                      <strong>connects</strong> to other cases through citations
                    </>
                  }
                  videoUrl="3.see_connections.mkv"
                />
              </Suspense>
            </div>
          </Flex>
        </Flex>
      </div>
      <Footer />
    </div>
  );
}

function AnimatedOnboardingStep({ description, videoUrl }) {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  return (
    <motion.div
      ref={ref}
      style={{ marginBottom: "50px", alignItems: "center" }}
      initial="hidden"
      animate={isInView ? "visible" : "hidden"}
      variants={{
        hidden: { opacity: 0, y: 50 },
        visible: { opacity: 1, y: 0 },
      }}
      transition={{ duration: 0.8 }}
    >
      <OnboardingStep description={description} videoUrl={videoUrl} />
    </motion.div>
  );
}
