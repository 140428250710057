import "./About.css";
import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import dan from "../assets/dan.jpg";
import haakon from "../assets/haakon.jpg";
import inger from "../assets/inger.jpg";
import lorenz from "../assets/lorenz.jpeg";
import nasjida from "../assets/nasjida.jpg";
import eric from "../assets/eric.jpg";
import Footer from "../components/Footer";

function About() {
  const handleNavigate = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  return (
    <div className="App">
      <header className="App-header">
        <div className="Top-ledger">
          <Link to="/">
            <img
              src={"meshr_full_logo.png"}
              className="Top-ledger-logo"
              alt="Top left logo"
            />
          </Link>
          <div className="Right-ledger">
            <button
              className="App-button App-button-purple"
              onClick={() => handleNavigate("https://staging.meshr.org")}
            >
              Log in
            </button>
          </div>
        </div>
      </header>
      <img src={"purple_blob.png"} className="Purple-blob" alt="Purple blob" />
      <div style= {{ marginTop: "100px" }} className="Description-box">
        <h1>About the project</h1>
        <p>
          <strong>MESHR</strong> is an online legal information system where you
          can navigate, visualise and identify case law from the European Court of Human Rights
          <br />
          <br />
          <strong>MESHR</strong> is a tool created with students in mind. <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </p>
      </div>
      <div className="Content-wrapper">
        <p className="team-heading">
          The <strong>MESHR</strong> team:
        </p>
        <div className="team-container">
          <div className="team-member">
            <img
              src={inger}
              alt="Inger Marie Kristiansen Liepelt"
              className="team-member-photo"
            />
            <h2 className="team-member-name">
              Inger Marie <br /> Kristiansen Liepelt
            </h2>
          </div>
          <div className="team-member">
            <img
              src={haakon}
              alt="Håkon Liltved Hyrve"
              className="team-member-photo"
            />
            <h2 className="team-member-name">Håkon Liltved Hyrve</h2>
          </div>
          <div
            className="team-member"
            onClick={() =>
              window.open("https://www.linkedin.com/in/nasjida-n/", "_blank")
            }
          >
            <img
              src={nasjida}
              alt="Nasjida Noorestany"
              className="team-member-photo"
            />
            <h2 className="team-member-name">Nasjida Noorestany</h2>
          </div>
          <div className="team-member">
            <img
              src={lorenz}
              alt="Lorenz T. Keefer"
              className="team-member-photo"
            />
            <h2 className="team-member-name">Lorenz Keefer</h2>
          </div>
          <div className="team-member">
            <img src={dan} alt="Dan Uehara" className="team-member-photo" />
            <h2 className="team-member-name">Dan Uehara</h2>
          </div>
          <div className="team-member">
            <img
              src={eric}
              alt="Eric Svebakk"
              className="team-member-photo"
            />
            <h2 className="team-member-name">Eric Svebakk</h2>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default About;
