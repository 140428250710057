import "./Footer.css"
export default function Footer() {
  return (
    <footer className="App-footer">
      <img src={"uio-logo-no.svg"} alt="University of Oslo" />
      <p>Contact:
        <br/> 
        <a href="mailto:support@meshr.org">support@meshr.org</a></p>
    </footer>
  );
}
